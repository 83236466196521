import React, { useState, useEffect } from "react";
import "./AddVideo.css";
import { fetchCategories } from "../../Redux/category/category.action";
import { fetchSubCategories } from "../../Redux/subCategory/sub-category.action";
import { setSelectedService } from "../../Redux/user/user.action";
import { fetchArtists } from "../../Redux/artists/artists.action";
import { useDispatch, useSelector } from "react-redux";
import { uploadVideo, updateVideo } from "../../Redux/video/video.action";
import { NotificationManager } from 'react-notifications';
import { Alert } from '../../Components/UI/Alert/alert'

export const AddVideo = ({ match, history }) => {
  const { id } = match.params
  const [name, setname] = useState();
  const [selectedCategory, setselectedCategory] = useState();
  const [selectedSubCategory, setselectedSubCategory] = useState();
  const [status, setstatus] = useState();
  const [playStatus, setplayStatus] = useState();
  const [description, setdescription] = useState();
  const [selectedArtist, setselectedArtist] = useState('');
  const [image, setimage] = useState()
  const [whiteImage, setwhiteImage] = useState()
  const [video, setvideo] = useState()


  const { services } = useSelector((state) => state.user);
  const { categories } = useSelector((state) => state.category);
  const { subCategories } = useSelector((state) => state.subCategory);
  const { selectedService } = useSelector((state) => state.user);
  const { artists } = useSelector((state) => state.artist);
  const { videos, uploadSuccess, error } = useSelector((state) => state.video);

  useEffect(() => {
    if (id && videos && videos.length > 0 ) {
      const filteredvid = videos.find(list => list.id == id)
      console.log('filteredvid', filteredvid)
      if (filteredvid) {
        setname(filteredvid.name)
        setselectedCategory(filteredvid.cat_id)
        setselectedSubCategory(filteredvid.sub_cat_id)
        setstatus(filteredvid.playstatus)
        setplayStatus(filteredvid.status)
        setdescription(filteredvid.description)
        setselectedArtist(filteredvid.artist)
        // setimage(filteredvid.imgurl)
        // setwhiteImage(filteredvid.whiteimage)
        // setvideo(filteredvid.vurl)
      }
    }
  }, [id, videos])

  console.log('videos', videos)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories(selectedService));
    dispatch(fetchArtists(selectedService));
    console.log("CATEGORIES", categories);
  }, [selectedService]);

  useEffect(() => {
    dispatch(fetchSubCategories(selectedCategory));
    console.log("CATEGORIES", categories);
  }, [selectedCategory]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      dispatch(updateVideo(id, name, selectedCategory, selectedSubCategory, status, playStatus, description, selectedArtist, image, whiteImage, video))
    } else {
      dispatch(uploadVideo(name, selectedCategory, selectedSubCategory, status, playStatus, description, selectedArtist, image, whiteImage, video))
    }

  }

  const clearState = () => {
    if(!id){
      window.location.reload()
    }
  }

  useEffect(() => {
    console.log('uploadSuccess', uploadSuccess)
    if (uploadSuccess) {
      setname('')
      setselectedCategory('')
      setselectedSubCategory('')
      setstatus('')
      setplayStatus('')
      setdescription()
      setselectedArtist('')
      setimage('')
      setwhiteImage('')
      setvideo('')
    }
  }, [uploadSuccess])
  return (
    <>

      <div className="main">
        {uploadSuccess ? <Alert Click={() => {
          dispatch({ type: 'CLEAR' })
          clearState()
        }
        } >{uploadSuccess}</Alert> : null}
        {error ? <Alert Click={() => 
          dispatch({ type: 'CLEAR' })
        } >Something went Wrong</Alert> : null}

        <div>
          <div className="jumbotron">
            <h3 className="title">Add Video</h3>
            <div className="container p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div class="form-group col-md">
                    <label for="inputEmail4">SELECT SERVICE</label>
                    <select
                      className="form-control"
                      value={selectedService}
                      onChange={(e) => {
                        dispatch(setSelectedService(e.target.value));
                      }}
                      required
                    >
                      <option value="" default>
                        Selected Service
                    </option>
                      {services.length > 0 &&
                        services.map((service) => {
                          return (
                            <option value={service.statuscode}>
                              {service.servicename}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                {selectedService && (
                  <>
                    <div class="row">
                      <div class="form-group col-md">
                        <label for="inputEmail4">Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="inputEmail4"
                          value={name}
                          onChange={(e) => setname(e.target.value)}
                          required
                        ></input>
                      </div>
                    </div>
                    <div className="row">
                      <div class="form-group col-md-6">
                        <label for="inputEmail4">SELECT CATEGORY</label>
                        <select
                          className="form-control"
                          value={selectedCategory}
                          onChange={(e) => {
                            setselectedCategory(e.target.value);
                          }}
                        >
                          <option value="" default>
                            Select Category...
                        </option>
                          {categories.length > 0 &&
                            categories.map((category) => {
                              return (
                                <option value={category.id}>
                                  {category.category_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="inputEmail4">SELECT SUB CATEGORY</label>
                        <select
                          className="form-control"
                          value={selectedSubCategory}
                          onChange={(e) => {
                            setselectedSubCategory(e.target.value);
                          }}
                        >
                          <option value="" default>
                            Select SubCategory...
                        </option>
                          {subCategories.length > 0 &&
                            subCategories.map((subCategory) => {
                              return (
                                <option value={subCategory.sub_cat_id}>
                                  {subCategory.sub_cat_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div class="form-group col-md-6">
                        <label for="inputState">SELECT ARTIST</label>
                        <select
                          className="form-control"
                          value={selectedArtist}
                          onChange={(e) => setselectedArtist(e.target.value)}
                        >
                          {console.log('SELected artist', selectedArtist)}
                          <option value="" selected>Select Artist...</option>
                          {
                            artists.length !== 0 && artists.map((artist) => {
                              return (
                                <option value={artist.id}>{artist.artist}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div class="form-group col-md-6">
                        <label for="inputState">Video Status</label>
                        <select
                          id="inputState"
                          class="form-control"
                          value={status}
                          onChange={(e) => setstatus(e.target.value)}
                          required
                        >
                          <option selected>Choose...</option>
                          <option value={selectedService}>Active</option>
                          <option value="0">Inactive</option>
                        </select>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="inputState">Play Status</label>
                        <select
                          id="inputState"
                          class="form-control"
                          value={playStatus}
                          onChange={(e) => setplayStatus(e.target.value)}
                          required
                        >
                          <option selected>Choose...</option>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </select>

                      </div>
                    </div>
                    <div className="row">
                      <div class="form-group col-md">
                        <label for="inputState">Description</label>
                        <textarea
                          className="form-control"
                          value={description}
                          onChange={(e) => setdescription(e.target.value)}
                          required
                        ></textarea>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div class="input-group">
                          <div class="custom-file">
                            <label
                              class="custom-file-label"
                              for="inputGroupFile01"
                            >
                              Upload Image
                          </label>
                            <input
                              type="file"
                              class="custom-file-input form-control"
                              id="inputGroupFile01"
                              aria-describedby="inputGroupFileAddon01"
                              onChange={(e) => setimage(e.target.files[0])}
                              required={id ? false : true}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="input-group">
                          <div class="custom-file">
                            <label
                              class="custom-file-label"
                              for="inputGroupFile01"
                            >
                              White Image
                          </label>
                            <input
                              type="file"
                              class="custom-file-input form-control"
                              id="inputGroupFile01"
                              aria-describedby="inputGroupFileAddon01"
                              onChange={(e) => setwhiteImage(e.target.files[0])}
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="input-group">
                        <div class="custom-file">
                          <label class="custom-file-label" for="inputGroupFile01">
                            Upload Video
                        </label>
                          <input
                            type="file"
                            class="custom-file-input form-control"
                            id="inputGroupFile01"
                            aria-describedby="inputGroupFileAddon01"
                            onChange={(e) => setvideo(e.target.files[0])}
                            required={id ? false : true}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <button className="btn btn-success" type="submit">Submit</button>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
