import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Login } from './Pages/Login/Login'
import { setToken, setUsername } from './Redux/user/user.action'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Dashboard } from './Pages/Dashboard/Dashboard'
import './App.css'
import LoadingOverlay from 'react-loading-overlay';
import { isAuthenticated, PrivateRoute } from "./helpers/helpers"
import SubCategory from './Pages/SubCategory/SubCategory'


const App = () => {

    const { token } = useSelector(state => state.user)
    const { loading } = useSelector(state => state.video)

    const dispatch = useDispatch()

    useEffect(() => {
        let foundedToken = localStorage.getItem('token')
        if (foundedToken) {
            dispatch(setToken(foundedToken))
        }
        console.log(token);
    }, [])

    // useEffect(() =>{
    //     if(token){
    //        <Redirect to='/dashboard'/>
    //     }else{
    //         <Redirect to='/'/>
    //     }
    // },[token])

    if (token !== '') {
        return (
            <>
                <LoadingOverlay
                    style={{ position: 'fixed' }}
                    active={loading}
                    spinner
                    text='Loading...'
                >
                    <Router>
                        <Dashboard />
                    </Router>
                </LoadingOverlay>
            </>
        )
    } else {
        return (
            <Login />
        )
    }
}

export default App

