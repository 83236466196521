import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, setLoginFailed } from "../../Redux/user/user.action";
import "./Login.css";

import { useAlert } from "react-alert";
import {isAuthenticated} from '../../helpers/helpers'
import {useHistory} from 'react-router-dom'

export const Login = () => {

  // console.log('TOKEN',isAuthenticated());

  const {loginFailed,token} = useSelector(state => state.user)

  const history = useHistory()

  const dispatch = useDispatch()
  const [username, setusername] = useState('')
  const [password, setpassword] = useState('')

  const handleLogin = (e) =>{
       e.preventDefault()
      // history.push('/dashboard')
      dispatch(login({username,password}))
      // history.push('/dashboard')
      
  }

  // useEffect(() => {
  //     history.push('/dashboard')
  // }, [token])
  
  return (
    <div className="login-page">
     
      <div className="left-section">
       {console.log(loginFailed)}
      </div>
      <div className="right-section">
          <form className="login-container" onSubmit={handleLogin}>
              <h1 className="mb-4">Login</h1>
               <div className="input-group mb-3">
                   <label className="text-muted mb-1">Username:</label>
                   <input type="text" className="form-control" value={username} onChange={(e)=> setusername(e.target.value)}></input>
               </div>
               <div className="input-group">
                   <label className="text-muted mb-1">Password:</label>
                   <input type="Password" className="form-control" value={password} onChange={(e)=> setpassword(e.target.value)}></input>
               </div>
               <div>
                   <button className="btn btn-success mt-4 login-btn form-control" type="submit">Login</button>
               </div>
          </form>
      </div>
    </div>
  );
};
