import * as actionType from "./artists.type";

const initialState = {
   artists: [],
   loading: false,
   success: false,
   error: false
};
export const GET_ARTISTS = 'GET_ARTISTS'
export const GET_ARTISTS_SUCCESS = 'GET_ARTISTS_SUCCESS'
export const GET_ARTISTS_FAILED = 'GET_ARTISTS_FAILED'

export const artistsReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case actionType.GET_ARTISTS:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        artists: []
      };

      case actionType.GET_ARTISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        artists: action.payload
      };

      case actionType.GET_ARTISTS_FAILED:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload
        };

  }
  return state;
};
