import axios from "axios";
import { isAuthenticated } from "../../helpers/helpers";
import * as actionType from "./sub-category.type";

let token = isAuthenticated()

const getSubCategories = () => ({
  type: actionType.GET_SUB_CATEGORIES,
});

const getSubCategoriesSuccess = (data) => ({
  type: actionType.GET_SUB_CATEGORIES_SUCCESS,
  payload: data,
});

const getSubCategoriesFailed = (data) => ({
  type: actionType.GET_SUB_CATEGORIES_FAILED,
  payload: data,
});

export const fetchSubCategories = (category) => {

  return (dispatch) => {
    dispatch(getSubCategories());
    axios
      .get(`subCategory/${category}`)
      .then((res) => {
          if(res.data.err){
             console.log(res.data.err);           
             dispatch(getSubCategoriesFailed(res.data.err))
          }else{
            console.log('RESPONSE',res);
            dispatch(getSubCategoriesSuccess(res.data))
          }
 
      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };
}

//DELETE CATEGORY
export const deleteSubCategory = (id, selectedCategory) => {

  return (dispatch) => {
    // dispatch(getCategories());
    axios
      .delete(`subCategory/${id}`)
      .then((res) => {
          if(res.data.err){
             console.log(res.date.err);
          }else{
            console.log('RESPONSE',res);
            dispatch(fetchSubCategories(selectedCategory))
          }
 
      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };
}


export const createSubCategory = (subcategory,selectedCategory) => {

  return (dispatch) => {
    // dispatch(getCategories());


    axios
      .post(`subCategory/${selectedCategory}`,{sub_cat_name: subcategory,status: 0})
      .then((res) => {
          if(res.data.err){
             console.log(res.date.err);
          }else{
            console.log('RESPONSE',res);
            alert('Sub CategoryAdded Successfully!')
            dispatch(fetchSubCategories(selectedCategory))
          }
 
      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };
}

