import React from 'react'
import {Button} from 'react-bootstrap'

export const Alert = ({children, Click}) => {
    return (
        <div className="alert-wrap" >
            <div className="alert " >
                <p>{children} </p>
                <Button onClick={Click} variant="warning" size="sm">OK</Button>
            </div>

        </div>
    )
}