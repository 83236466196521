import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createArtist } from "../../../Redux/artists/artists.action";
import { updateCategory } from "../../../Redux/category/category.action";
import { createSubCategory } from "../../../Redux/subCategory/sub-category.action";

export const UpdateCategoryModal = ({
  showModal,
  handleClose,
  modalType,
  selectedCategory,
  id
}) => {
  const { selectedService } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [category, setcategory] = useState("");
  const [subCategory, setsubCategory] = useState('');
  const [artist, setartist] = useState('')

  const [image, setimage] = useState("");



  console.log(showModal);
  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closebutton>
        <Modal.Title>
          {modalType == "1" && "Update Category"}
          {modalType == "2" && "Add Sub-Category"}
          {modalType == "3" && "Add Artist"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="input-group"> */}
        {modalType == "1" && (
          <>
            {" "}
            <div className="row">
              <div className="col mb-2">
                <label>Name:</label>
                <input
                  type="text"
                  className="form-control"
                  value={category}
                  onChange={(e) => setcategory(e.target.value)}
                ></input>
              </div>
            </div>
            <div>
              <div className="col">
                <label>Image:</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setimage(e.target.files[0])}
                ></input>
              </div>
            </div>
          </>
        )}

        {modalType == "2" && (
          <>
            {" "}
            <div className="row">
              <div className="col mb-2">
                <label>Name:</label>
                <input
                  type="text"
                  className="form-control"
                  value={subCategory}
                  onChange={(e) => setsubCategory(e.target.value)}
                ></input>
              </div>
            </div>
          </>
        )}

        {modalType == "3" && (
          <>
            {" "}
            <div className="row">
              <div className="col mb-2">
                <label>Name:</label>
                <input
                  type="text"
                  className="form-control"
                  value={artist}
                  onChange={(e) => setartist(e.target.value)}
                ></input>
              </div>
            </div>
            <div>
              <div className="col">
                <label>Image:</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setimage(e.target.files[0])}
                ></input>
              </div>
            </div>
          </>
        )}

        {/* </div> */}
      </Modal.Body>
      <Modal.Footer>
        {modalType == "1" && (
          <Button
            variant="primary"
            onClick={() =>
              dispatch(updateCategory(selectedService, category, id,  image))
            }
          >
            Update Category
          </Button>
        )}

        {modalType == "2" && (
          <Button
            variant="primary"
            onClick={() =>
              dispatch(createSubCategory(subCategory, selectedCategory))
            }
          >
            Add Sub Category
          </Button>
        )}

        {modalType == "3" && (
          <Button
            variant="primary"
            onClick={() =>
              dispatch(createArtist(selectedService, artist, 0, image))
            }
          >
            Add Artist
          </Button>
        )}

        <Button
          variant="secondary"
          onClick={() => {
            handleClose(!showModal);
            setcategory("");
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
