import React, { useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";


import { useDispatch, useSelector } from "react-redux";
import { MAINURL } from "../..";
import { deleteCategory } from "../../Redux/category/category.action";
import { deleteSubCategory } from "../../Redux/subCategory/sub-category.action";
import { toggleVideoStatus, deleteVideo } from "../../Redux/video/video.action";
import { useHistory } from 'react-router-dom'
const customStyles = {
  rows: {
    style: {
      // minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      // paddingLeft: '8px', // override the cell padding for head cells
      // paddingRight: '8px',
      // border: '1px solid',
      // borderRadius: '10px',
      justifyContent: "center",
      fontWeight: 800,
      textTransform: "uppercase",
      paddingBottom: "20px",
      marginTop: "30px",
      // height: '25px'
    },
  },
  cells: {
    style: {
      // paddingLeft: '8px', // override the cell padding for data cells
      // paddingRight: '8px',
      justifyContent: "center",
      fontWeight: 600,
      padding: "4px",
    },
  },
  title: {
    style: {
      fontSize: "40px",
    },
  },
};

createTheme("solarized", {
  text: {
    //   primary: '#268bd2',
    //   secondary: '#2aa198',
  },
  background: {
    //   default: '#002b36',
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#ccd6d8",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

export const Table = ({ data, tableTitle, loading, selectedCategory }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const { selectedService } = useSelector(state => state.user)

  // let imgUrl = `${MAINURL}video/image/`
  // let videoUrl = `${MAINURL}video/video/`
  let columns = [
    {
      name: "VIDEO_ID",
      selector: "videoid",
      wrap: true,
      sortable: true
    },
    {
      name: "NAME",
      selector: "name",
      wrap: true,
      sortable: true
    },
    {
      name: "CATEGORY",
      selector: "category",
      wrap: true,
      sortable: true
    },
    {
      name: "DESCRIPTION",
      selector: "description",
    },
    {
      name: "VIEW VIDEO",
      //   cell: row => <a class="btn btn-primary" href={row.vurl}>View</a>
      cell: row =>
         <a target="_blank" class="btn btn-primary" href={row.vurl} target="_blank">View</a>
   
    },
    {
      name: "VIEW IMAGE",
      // cell: row => <a class="btn btn-primary" href={row.imgurl}>View</a>
      cell: row => <><img src={row.imgurl} height="50px" width="90px"></img></>

    },
    {
      name: "STATUS",
      cell: row => <div class="form-check form-switch"> <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={(e) => {
        console.log(e.target.checked)
        if (e.target.checked) {
          dispatch(toggleVideoStatus(row.cat_id, row.id, selectedService))
        } else {
          dispatch(toggleVideoStatus(row.cat_id, row.id, 0))
        }

      }} defaultChecked={row.status}></input></div>
    },
    {
      name: "ACTION",
      width: '200px',
      cell: row => <><button className="btn btn-outline-warning mx-2" onClick={()=>history.push(`/video-update/${row.id}`)} >Edit</button><button className="btn btn-outline-danger" onClick={() => {
        dispatch(deleteVideo(row.cat_id, row.id))
      }}>Delete</button></>
    },


    // {
    //   name: "NAME",
    //   cell: row => <> <button class="btn btn-warning">Edit</button><button class="btn btn-danger mx-3" onClick={()=> dispatch(deleteSubCategory(row.sub_cat_id,selectedCategory))}>Delete</button></>

    // }
  ];
  //   columns.push({

  //   })

  //   let Col_Array = data.map((list) => Object.keys(list));
  //   const Dispatch = useDispatch();

  //   if (Col_Array.length > 0) {
  //     Col_Array[0].map((list) => {
  //       if (list == "id" || list == "is_enable") {
  //       } else {
  //         columns.push({
  //           name: list.toUpperCase(),
  //           selector: list,
  //         });
  //       }
  //     });
  //   }
  return (
    <>
      <DataTable
        title={tableTitle}
        columns={columns}
        data={data}
        pagination={true}
        customStyles={customStyles}
        // responsive={true}
        dense={true}
        theme="solarized"
        loading={loading}

      />
    </>
  );
};
