import axios from "axios";
import * as actionType from "./user.type";

export const setUsername = (data) => ({
  type: actionType.SET_USERNAME,
  payload: data,
});

export const setPassword = (data) => ({
  type: actionType.SET_PASSWORD,
  payload: data,
});

export const setToken = (data) => ({
  type: actionType.SET_TOKEN,
  payload: data,
});

export const setLoginFailed = (data) => ({
  type: actionType.SET_LOGIN_FAILED,
  payload: data,
});

export const setLoading = () => ({
  type: actionType.SET_LOADING,
});

const setServices = (data) => ({
  type: actionType.SET_SERVICES,
  payload: data
});

export const setSelectedService = (data) => ({
  type: actionType.SET_SELECTED_SERVICE,
  payload: data
});


export const login = (data) => {
  console.log("Logging in....");

  console.log(data);
  return (dispatch) => {
    dispatch(setLoading());
    axios
      .post("user/signin/", data)
      .then((res) => {
        if (res.data.error) {
          dispatch(setLoginFailed(res.data.error));
          dispatch(setLoading());
        } else {
          dispatch(setToken(res.data.token));
          localStorage.setItem("token", res.data.token);
          console.log("resp", res);
          dispatch(setLoading());
          // history.push('/dashboard')
        }
      })
      .catch((err) => {
        console.log("ERROR:", err);
        dispatch(setLoading());
      });
  };
};

export const getServices = ()=>{

  return (dispatch) => {
    dispatch(setLoading());
    axios
      .get("services")
      .then((res) => {
        console.log('SERVICES', res.data);
          dispatch(setServices(res.data))
      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };

}