import axios from "axios";
import { isAuthenticated } from "../../helpers/helpers";
import * as actionType from "./video.type";


let token = isAuthenticated()

const uploadVideoStart = () => ({
  type: actionType.VIDEO_UPLOAD_START,
});

const uploadVideoSuccess = (data) => ({
  type: actionType.VIDEO_UPLOAD_SUCCESS,
  payload: data,
});

const uploadVideoFailed = (data) => ({
  type: actionType.VIDEO_UPLOAD_FAILED,
  payload: data,
});

const getVideosStart = () => ({
  type: actionType.GET_VIDEOS_START,
});

const getVideosSuccess = (data) => ({
  type: actionType.GET_VIDEOS_SUCCESS,
  payload: data,
});

const getVideosFailed = (data) => ({
  type: actionType.GET_VIDEOS_FAILED,
  payload: data,
});


export const getVideos = (catId) => {
  return (dispatch) => {
    dispatch(getVideosStart())

    axios
      .get(`videos/${catId}`)
      .then((res) => {
        if (res.data.err) {
          dispatch(getVideosFailed(res.data.err))
          console.log(res.date.err);
        } else {
          console.log('RESPONSE', res);
          dispatch(getVideosSuccess(res.data))
        }

      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  }
}

export const toggleVideoStatus = (catId, vidId, status) => {
  return (dispatch) => {


    axios
      .put(`video/${vidId}`, { status })
      .then((res) => {
        if (res.data.err) {

          console.log(res.date.err);
        } else {
          console.log('RESPONSE', res);
          dispatch(getVideos(catId))
        }

      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  }
}

export const updateVideo = (vidId, name, selectedCategory, selectedSubCategory, status, playStatus, description, selectedArtist, image, whiteImage, video) => {
  return (dispatch) => {
    // dispatch(uploadVideoStart());
    let fd = new FormData();
    // TODO
    fd.append('name', name)
    fd.append('cat_id', selectedCategory)
    if (selectedSubCategory != "undefined") {
      fd.append('sub_cat_id', selectedSubCategory)
    }

    fd.append('status', status)
    fd.append('playstatus', playStatus)
    fd.append('description', description)

    if (selectedArtist) {
      fd.append('artist', selectedArtist)
    }
    if (image) {

      fd.append('image', image)
    }

    if (whiteImage) {
      fd.append('whiteimage', whiteImage)
    }
    if (video) {
      fd.append('video', video)
    }
    // fd.append('id', vidId)
    axios
      .put(`/update/video/${vidId}`, fd)
      .then((res) => {
        if (res.data.err) {
          dispatch(uploadVideoFailed(res.data.err))
          console.log(res.date.err);
        } else {
          console.log('RESPONSE', res);
          // alert(res.data)
          dispatch(uploadVideoSuccess("Video Updated Successfully"))
          dispatch(getVideos(selectedCategory))
        }

      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  }
}


export const deleteVideo = (catId, vidId) => {
  return (dispatch) => {

    axios
      .delete(`video/${vidId}`)
      .then((res) => {
        if (res.data.err) {

          console.log(res.date.err);
        } else {
          console.log('RESPONSE', res);
          dispatch(getVideos(catId))
        }

      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  }
}


export const uploadVideo = (name, selectedCategory, selectedSubCategory, status, playStatus, description, selectedArtist, image, whiteImage, video) => {
  return (dispatch) => {
    // name,selectedCategory,selectedSubCategory,status,playStatus,description,selectedArtist
    dispatch(uploadVideoStart());



    let fd = new FormData();
    // TODO
    fd.append('name', name)
    fd.append('cat_id', selectedCategory)
    if (selectedCategory) {
      fd.append('sub_cat_id', selectedSubCategory)
    }

    fd.append('status', status)
    fd.append('playstatus', playStatus)
    fd.append('description', description)

    if (selectedArtist) {
      fd.append('artist', selectedArtist)
    }

    fd.append('image', image)

    if (whiteImage) {
      fd.append('whiteimage', whiteImage)
    }

    fd.append('video', video)



    axios
      .post(`video`, fd)
      .then((res) => {
        if (res.data.err) {
          dispatch(uploadVideoFailed(res.data.err))
          console.log(res.date.err);
        } else {
          console.log('RESPONSE', res);
          // alert(res.data)
          dispatch(uploadVideoSuccess(res.data))
          
        }

      })
      .catch((err) => {
        // console.log("ERROR:", err);
        dispatch(uploadVideoFailed('Something went Wrong'))

      });
  };
}

// const getArtists = () => ({
//   type: actionType.GET_ARTISTS,
// });

// const getArtistsSuccess = (data) => ({
//   type: actionType.GET_ARTISTS_SUCCESS,
//   payload: data,
// });

// const getArtistsFailed = (data) => ({
//   type: actionType.GET_ARTISTS_FAILED,
//   payload: data,
// });

// export const fetchArtists = (service) => {

//   return (dispatch) => {
//     dispatch(getArtists());
//     axios
//       .get(`artists/${service}`)
//       .then((res) => {

//           if(res.data.err){
//              console.log(res.date.err);
//             //  dispatch(getArtistsSuccess([]))
//           }else{
//             dispatch(getArtistsSuccess(res.data))
//             console.log('RESPONSE',res);

//           }

//       })
//       .catch((err) => {
//         console.log("ERROR:", err);
//       });
//   };
// }

//DELETE CATEGORY
// export const deleteArtist = (id, selectedService) => {

//   return (dispatch) => {
//     // dispatch(getCategories());
//     axios
//       .delete(`artist/${id}`)
//       .then((res) => {
//           if(res.data.err){
//              console.log(res.date.err);
//           }else{
//             console.log('RESPONSE',res);
//             dispatch(fetchArtists(selectedService))
//           }

//       })
//       .catch((err) => {
//         console.log("ERROR:", err);
//       });
//   };
// }


// export const createArtist = (selectedService,artist,status,image) => {

//   return (dispatch) => {
//     // dispatch(getCategories());

//     let fd = new FormData();
//     fd.append('artist_name',artist)
//     fd.append('artistImg',image)
//     fd.append('status', 0)

//     axios
//       .post(`artist/${selectedService}`,fd)
//       .then((res) => {
//           if(res.data.err){
//              console.log(res.date.err);
//           }else{
//             console.log('RESPONSE',res);
//             dispatch(fetchArtists(selectedService))
//           }

//       })
//       .catch((err) => {
//         console.log("ERROR:", err);
//       });
//   };
// }

