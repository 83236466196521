import { combineReducers } from "redux";
import { artistsReducer } from "./artists/artists.reducer";
import { categoryReducer } from "./category/category.reducer";
import { subCategoryReducer } from "./subCategory/sub-category.reducer";
import { userReducer } from "./user/user.reducer";
import { videoReducer } from "./video/video.reducer";

const rootReducer = combineReducers({
     user: userReducer,
     category: categoryReducer,
     subCategory: subCategoryReducer,
     artist: artistsReducer,
     video: videoReducer
})

export default rootReducer