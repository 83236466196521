import * as actionType from "./video.type";

const initialState = {
   videos: [],
   loading: false,
   success: false,
   uploadSuccess: false,
   error: false
};

export const videoReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case actionType.VIDEO_UPLOAD_START:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        videos: [],
        uploadSuccess : false
      };

      case actionType.VIDEO_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadSuccess: action.payload,
        error: false,
      };

      case actionType.VIDEO_UPLOAD_FAILED:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload
        };

      case actionType.GET_VIDEOS_START:
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
          videos: []
        };

        case actionType.GET_VIDEOS_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          videos: action.payload
        };

        
        case actionType.GET_VIDEOS_FAILED:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };
        case "CLEAR":
          return {
            ...state,
            loading: false,
            success: false,
            error: false,
        uploadSuccess : false
          };

  }
  return state;
};
