import React from "react";
import { Navlink, Link } from "react-router-dom";
import "./Sidebar.css";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="logo">
        <img src="https://logos.textgiraffe.com/logos/logo-name/Admin-designstyle-pastel-m.png"></img>
      </div>
      <hr></hr>
      <ul className="nav-bar">
        <li>
          <Link to="/add-video" className="nav-link">
            Add Videos
          </Link>
        </li>
        <li>
          <Link to="/videos" className="nav-link">
            Video List
          </Link>
        </li>
        <li>
          <Link to="/category" className="nav-link">
          Category
          </Link>
        </li>
        <li>
          <Link to="/sub-category" className="nav-link">
            Sub-Category
          </Link>
        </li>
        <li>
          <Link to="/artists" className="nav-link">
            Artists
          </Link>
        </li>

      </ul>
    </div>
  );
};

export default Sidebar;
