import * as actionType from "./sub-category.type";

const initialState = {
   subCategories: [],
   loading: false,
   success: false,
   error: false
};

export const subCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case actionType.GET_SUB_CATEGORIES:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        subCategories: []
      };

      case actionType.GET_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action,
        error: false,
        subCategories: action.payload
      };

      case actionType.GET_SUB_CATEGORIES_FAILED:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload
        };

  }
  return state;
};
