import React, { useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";


import { useDispatch, useSelector } from "react-redux";
import { deleteArtist } from "../../Redux/artists/artists.action";
import { deleteCategory } from "../../Redux/category/category.action";

const customStyles = {
  rows: {
    style: {
      // minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      // paddingLeft: '8px', // override the cell padding for head cells
      // paddingRight: '8px',
      // border: '1px solid',
      // borderRadius: '10px',
      justifyContent: "center",
      fontWeight: 800,
      textTransform: "uppercase",
      paddingBottom: "20px",
      marginTop: "30px",
      // height: '25px'
    },
  },
  cells: {
    style: {
      // paddingLeft: '8px', // override the cell padding for data cells
      // paddingRight: '8px',
      justifyContent: "center",
      fontWeight: 600,
      padding: "4px",
    },
  },
  title: {
    style: {
      fontSize: "40px",
    },
  },
};

createTheme("solarized", {
  text: {
    //   primary: '#268bd2',
    //   secondary: '#2aa198',
  },
  background: {
    //   default: '#002b36',
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#ccd6d8",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

export const Table = ({ data, tableTitle, loading }) => {

  const dispatch = useDispatch()
  const {selectedService} = useSelector(state => state.user)

  let columns = [
            {
              name: "ARTIST_NAME",
              selector: "artist",
            },
            {
              name: "IMAGE",
              cell: row => <img src={row.artist_img_url} height="50px" width="100px"></img> ,
            

            },
            {
              name: "STATUS",
              cell: row => <div class="form-check form-switch"> <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked={row.status}></input></div>
          },
            {
              name: "ACTION",
              cell: row => <> <button class="btn btn-warning">Edit</button><button class="btn btn-danger mx-3" onClick={()=> dispatch(deleteArtist(row.id,selectedService))}>Delete</button></>

            }
  ];
  columns.push({
     
  })

  let Col_Array = data.map((list) => Object.keys(list));
  const Dispatch = useDispatch();

  // if (Col_Array.length > 0) {
  //   Col_Array[0].map((list) => {
  //     if (list == "id" || list == "is_enable") {
  //     } else {
  //       columns.push({
  //         name: list.toUpperCase(),
  //         selector: list,
  //       });
  //     }
  //   });
  // }


  return (
    <>
      <DataTable
        title={tableTitle}
        columns={columns}
        data={data}
        pagination={true}
        customStyles={customStyles}
        // responsive={true}
        dense={true}
        theme="solarized"
        loading = {loading}
        
      />
    </>
  );
};
