import * as actionType from "./user.type";

const initialState = {
  username: "",
  password: "",
  token: "",
  user: "",
  loginFailed: false,
  loading: false,
  services: [],
  selectedService: ''
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionType.SET_USERNAME:
      return {
        ...state,
        username: action.payload,
      };

    case actionType.SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };

      
    case actionType.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
      
    case actionType.SET_LOGIN_FAILED:
      return {
        ...state,
        loginFailed: action.payload,
      };

      case actionType.SET_LOADING:
        return {
          ...state,
          loading : !state.loading,
        };

        case actionType.SET_SERVICES:
          return {
            ...state,
            services: action.payload
          };

          case actionType.SET_SELECTED_SERVICE:
            return {
              ...state,
              selectedService: action.payload
          };
  }
  return state;
};
