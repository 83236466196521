import React from 'react'
import { useDispatch } from 'react-redux'
import { logOut,setToken } from '../../../Redux/user/user.action'
import {useHistory} from 'react-router-dom'

const Header = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    return (
        <div className="header">
            <h3 className="text-white p-3">Welcome to Admin Panel</h3>
            <button className="m-3 btn btn-warning" onClick={() =>{
                dispatch(setToken(''))
                localStorage.clear();
                // history.push('/')                  
            }}>Logout</button>
        </div>
    )
}

export default Header
