import React,{useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddCategoryModal } from "../../Components/UI/AddCategoryModal.js/AddCategoryModal";
import { Table } from "../../Containers/Tables/ArtistTable";
import { fetchArtists } from "../../Redux/artists/artists.action";
import { fetchCategories } from "../../Redux/category/category.action";
import { setSelectedService } from "../../Redux/user/user.action";


function Artists() {


 const [showModal, setshowModal] = useState(false)

  const { services } = useSelector((state) => state.user);
  const { artists } = useSelector((state) => state.artist);
  const dispatch = useDispatch()
  const {selectedService} = useSelector(state => state.user)


  useEffect(() => {
      dispatch(fetchArtists(selectedService))
      console.log('ARTITS',artists);
  }, [selectedService])

  return (
    <div className="main">
      <div>
        <div className="jumbotron">
          <h3 className="title">Artists</h3>
          <div className="container p-4">
            {/* <form> */}
              <div class="row">
                <div class="form-group col-md">
                  <label for="inputEmail4">SELECT SERVICE</label>
                  <select className="form-control" value={selectedService} onChange={(e)=>{dispatch(setSelectedService(e.target.value))}}>
                    <option value="" default>Selected Service</option>
                    {services.length > 0 &&
                      services.map((service) => {
                        return (
                          <option value={service.statuscode}>
                            {service.servicename}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              {selectedService &&
                <>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={()=> setshowModal(!showModal)}>Add Artists</button>
                  </div>
                  <Table title="Categories" data={artists}></Table>
                </>
              }
            {/* </form> */}
          </div>
        </div>
      </div>
      <AddCategoryModal showModal={showModal} handleClose={setshowModal} modalType="3"/>
    </div>
  );
}

export default Artists;
