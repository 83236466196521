import React,{useEffect} from "react";
import Header from "../../Components/UI/Header/Header";

import Sidebar from "../../Components/UI/Sidebar/Sidebar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./Dashboard.css";
import { AddVideo } from "../AddVideo/AddVideo";
import Category from "../Category/Category";
import { getServices } from "../../Redux/user/user.action";
import { useDispatch } from "react-redux";
import SubCategory from "../SubCategory/SubCategory";
import Artists from "../Artists/Artists";
import Videos from "../Videos/Videos";
export const Dashboard = () => {

  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(getServices());

  }, []);

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="section">
        <Header />
        <Route path="/add-video" component={AddVideo} exact />
        <Route path="/videos" component={Videos} exact />
        <Route path="/category" component={Category} exact />
        <Route path="/sub-category" component={SubCategory} exact />
        <Route path="/artists" component={Artists} exact />
        <Route path="/video-update/:id" component={AddVideo} exact />
       
        {/* <Main/> */}
      </div>
    </div>
  );
};
