import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {store} from './Redux/store' 
import { Provider } from 'react-redux'
import axios from 'axios'

import { NotificationContainer } from "react-notifications";

// export const MAINURL = 'http://localhost:4000/api/'
export const MAINURL = 'https://api-raceday.gamesawaari.com/api/'

axios.defaults.baseURL = MAINURL;
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// Add a request interceptor
// axios.interceptors.request.use(request=> {
  // Do something before request is sent
  // console.log(config)
//   return request;
// }, function (error) {
  // Do something with request error
//   return Promise.reject(error);
// });

// Add a response interceptor
// axios.interceptors.response.use(response=> {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
//   return response;
// }, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
//   return Promise.reject(error);
// });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <NotificationContainer />

    <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
