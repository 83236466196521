import React from 'react';
import { useSelector } from 'react-redux';
import {Route,Redirect} from 'react-router-dom'


  
export function PrivateRoute({ component: Component, ...rest }) {

  const {token} = useSelector(state => state.user)
      // const token = isAuthenticated()
console.log('TOKEN',token);
    return (
        <Route
          {...rest}
          render={props =>
              token ? (
              <Component {...props}/>
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      );
  }
  
  

  export const isAuthenticated = ()=>{
    
    if(typeof window == "undefined"){
        return false
    }
 
    if(localStorage.getItem("token")){
        return localStorage.getItem("token")
    }else{
        return false
    }
 }