import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddCategoryModal } from "../../Components/UI/AddCategoryModal.js/AddCategoryModal";
import { Table } from "../../Containers/Tables/SubCategoryTable";
import { fetchCategories } from "../../Redux/category/category.action";
import { fetchSubCategories } from "../../Redux/subCategory/sub-category.action";
import { setSelectedService } from "../../Redux/user/user.action";

function SubCategory() {
  const [showModal, setshowModal] = useState(false);
  const [selectedCategory, setselectedCategory] = useState();

  const { services } = useSelector((state) => state.user);
  const { categories } = useSelector((state) => state.category);
  const { subCategories } = useSelector((state) => state.subCategory);
  const dispatch = useDispatch();
  const { selectedService } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchCategories(selectedService));
    console.log("CATEGORIES", categories);
  }, [selectedService]);

  useEffect(() => {
    dispatch(fetchSubCategories(selectedCategory));
    console.log("CATEGORIES", categories);
  }, [selectedCategory]);

  return (
    <div className="main">
      <div>
        <div className="jumbotron">
          <h3 className="title">Sub Category</h3>
          <div className="container p-4">
            {/* <form> */}
            <div class="row">
              <div class="form-group col-md">
                <label for="inputEmail4">SELECT SERVICE</label>
                <select
                  className="form-control"
                  value={selectedService}
                  onChange={(e) => {
                    dispatch(setSelectedService(e.target.value));
                  }}
                >
                  <option value="" default>
                    Selected Service
                  </option>
                  {services.length > 0 &&
                    services.map((service) => {
                      return (
                        <option value={service.statuscode}>
                          {service.servicename}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            {selectedService && (
              <>
                <div class="row">
                  <div class="form-group col-md">
                    <label for="inputEmail4">SELECT CATEGORY</label>
                    <select
                      className="form-control"
                      value={selectedCategory}
                      onChange={(e) => {
                        setselectedCategory(e.target.value);
                      }}
                    >
                      <option value="" default>
                        Selected Category
                      </option>
                      {categories.length > 0 &&
                        categories.map((category) => {
                          return (
                            <option value={category.id}>
                              {category.category_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </>
            )}

            { selectedCategory && <><div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => setshowModal(!showModal)}
                  >
                    Add Sub Category
                  </button>
                </div>
                <Table title="Categories" data={subCategories}   selectedCategory={selectedCategory}></Table></>}
            {/* </form> */}
          </div>
        </div>
      </div>
      <AddCategoryModal
        showModal={showModal}
        handleClose={setshowModal}
        modalType="2"
        selectedCategory={selectedCategory}
      />
    </div>
  );
}

export default SubCategory;
