import * as actionType from "./category.type";

const initialState = {
   categories: [],
   loading: false,
   success: false,
   error: false
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case actionType.GET_CATEGORIES:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        categories: []
      };

      case actionType.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action,
        error: false,
        categories: action.payload
      };

      case actionType.GET_CATEGORIES_FAILED:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload
        };

  }
  return state;
};
