import axios from "axios";
import { isAuthenticated } from "../../helpers/helpers";
import * as actionType from "./category.type";

let token = isAuthenticated()

const getCategories = () => ({
  type: actionType.GET_CATEGORIES,
});

const getCategoriesSuccess = (data) => ({
  type: actionType.GET_CATEGORIES_SUCCESS,
  payload: data,
});

const getCategoriesFailed = (data) => ({
  type: actionType.GET_CATEGORIES_FAILED,
  payload: data,
});

export const fetchCategories = (service) => {

  return (dispatch) => {
    dispatch(getCategories());
    axios
      .get(`categories/${service}`)
      .then((res) => {
        if (res.data.err) {
          console.log(res.data.err);
        } else {
          console.log('RESPONSE', res);
          dispatch(getCategoriesSuccess(res.data))
        }

      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };
}

//DELETE CATEGORY
export const deleteCategory = (id, selectedService) => {

  return (dispatch) => {
    // dispatch(getCategories());
    axios
      .delete(`category/${id}`)
      .then((res) => {
        if (res.data.err) {
          console.log(res.date.err);
        } else {
          console.log('RESPONSE', res);
          dispatch(fetchCategories(selectedService))
        }

      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };
}

//UPDATE CATEGORY
export const updateCategory = (selectedService, category, catId, image) => {
  console.log(selectedService + ": " + catId + ":" + category + ":" + image)

  let fd = new FormData();
  fd.append('category_name', category)
  fd.append('catImg', image)

  return (dispatch) => {
    axios
      .put(`category/${catId}`, fd)
      .then((res) => {
        if (res.data.err) {
          console.log(res.date.err);
        } else {
          console.log('RESPONSE', res);
          dispatch(fetchCategories(selectedService))
        }

      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };
}

export const switchCategory = (selectedService, catId, status) => {
  let fd = new FormData();
  fd.append('status', status)
  return (dispatch) => {
    axios
      .put(`category/${catId}`, fd)
      .then((res) => {
        if (res.data.err) {
          console.log(res.date.err);
        } else {
          console.log('RESPONSE', res);
          dispatch(fetchCategories(selectedService))
        }

      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };
}


export const createCategory = (category, status, selectedService, image) => {

  return (dispatch) => {
    // dispatch(getCategories());

    let fd = new FormData();
    fd.append('category_name', category)
    fd.append('catImg', image)
    fd.append('status', 0)

    axios
      .post(`category/${selectedService}`, fd)
      .then((res) => {
        if (res.data.err) {
          console.log(res.date.err);
        } else {
          console.log('RESPONSE', res);
          dispatch(fetchCategories(selectedService))
        }

      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };
}

