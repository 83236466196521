import axios from "axios";
import { isAuthenticated } from "../../helpers/helpers";
import * as actionType from "./artists.type";


let token = isAuthenticated()

const getArtists = () => ({
  type: actionType.GET_ARTISTS,
});

const getArtistsSuccess = (data) => ({
  type: actionType.GET_ARTISTS_SUCCESS,
  payload: data,
});

const getArtistsFailed = (data) => ({
  type: actionType.GET_ARTISTS_FAILED,
  payload: data,
});

export const fetchArtists = (service) => {

  return (dispatch) => {
    dispatch(getArtists());
    axios
      .get(`artists/${service}`)
      .then((res) => {
       
          if(res.data.err){
             console.log(res.date.err);
            //  dispatch(getArtistsSuccess([]))
          }else{
            dispatch(getArtistsSuccess(res.data))
            console.log('RESPONSE',res);
            
          }
 
      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };
}

//DELETE CATEGORY
export const deleteArtist = (id, selectedService) => {

  return (dispatch) => {
    // dispatch(getCategories());
    axios
      .delete(`artist/${id}`)
      .then((res) => {
          if(res.data.err){
             console.log(res.date.err);
          }else{
            console.log('RESPONSE',res);
            dispatch(fetchArtists(selectedService))
          }
 
      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };
}


export const createArtist = (selectedService,artist,status,image) => {

  return (dispatch) => {
    // dispatch(getCategories());

    let fd = new FormData();
    fd.append('artist_name',artist)
    fd.append('artistImg',image)
    fd.append('status', 0)

    axios
      .post(`artist/${selectedService}`,fd)
      .then((res) => {
          if(res.data.err){
             console.log(res.date.err);
          }else{
            console.log('RESPONSE',res);
            dispatch(fetchArtists(selectedService))
          }
 
      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };
}

